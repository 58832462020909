import React, { FC } from 'react';

import RichText from '@components/RichText/RichText';
import { useLinkComponent } from '@link';
import { MessageBarRendering } from '@sitecore/types/manual/MessageBar';
import { MessageBar as SparkyMessageBar } from '@sparky';

const MessageBar: FC<MessageBarRendering> = ({ fields: { title, content, link }, params }) => {
  const Link = useLinkComponent();

  return (
    <Link editable={link.editable} linkType={link.value.linktype} linkValue={link.value}>
      <SparkyMessageBar
        headingLevel={params?.headingLevel}
        text={<RichText html={content.value} />}
        title={title.value}
      />
    </Link>
  );
};

export default MessageBar;
